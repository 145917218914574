exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-duszpasterstwo-chorych-js": () => import("./../../../src/pages/duszpasterstwo-chorych.js" /* webpackChunkName: "component---src-pages-duszpasterstwo-chorych-js" */),
  "component---src-pages-duszpasterze-js": () => import("./../../../src/pages/duszpasterze.js" /* webpackChunkName: "component---src-pages-duszpasterze-js" */),
  "component---src-pages-galeria-aktualne-js": () => import("./../../../src/pages/galeria/aktualne.js" /* webpackChunkName: "component---src-pages-galeria-aktualne-js" */),
  "component---src-pages-galeria-archiwum-index-js": () => import("./../../../src/pages/galeria/archiwum/index.js" /* webpackChunkName: "component---src-pages-galeria-archiwum-index-js" */),
  "component---src-pages-galeria-index-js": () => import("./../../../src/pages/galeria/index.js" /* webpackChunkName: "component---src-pages-galeria-index-js" */),
  "component---src-pages-ministranci-js": () => import("./../../../src/pages/ministranci.js" /* webpackChunkName: "component---src-pages-ministranci-js" */),
  "component---src-pages-roze-js": () => import("./../../../src/pages/roze.js" /* webpackChunkName: "component---src-pages-roze-js" */),
  "component---src-pages-rycerze-kolumba-js": () => import("./../../../src/pages/rycerze-kolumba.js" /* webpackChunkName: "component---src-pages-rycerze-kolumba-js" */),
  "component---src-pages-sanktuarium-js": () => import("./../../../src/pages/sanktuarium.js" /* webpackChunkName: "component---src-pages-sanktuarium-js" */),
  "component---src-pages-schola-js": () => import("./../../../src/pages/schola.js" /* webpackChunkName: "component---src-pages-schola-js" */),
  "component---src-pages-slowo-na-dzis-js": () => import("./../../../src/pages/slowo-na-dzis.js" /* webpackChunkName: "component---src-pages-slowo-na-dzis-js" */),
  "component---src-pages-standardy-ochrony-maloletnich-js": () => import("./../../../src/pages/standardy-ochrony-maloletnich.js" /* webpackChunkName: "component---src-pages-standardy-ochrony-maloletnich-js" */),
  "component---src-templates-album-template-js": () => import("./../../../src/templates/album-template.js" /* webpackChunkName: "component---src-templates-album-template-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-new-category-template-js": () => import("./../../../src/templates/new-category-template.js" /* webpackChunkName: "component---src-templates-new-category-template-js" */)
}

